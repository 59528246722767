import "./index.css"

export default function about() { 
    return(
        <div className="wrapper">
            <p className="info__text">Under construction</p>
            <div className="loader">
                <div className="white_out"></div>
            </div>
        </div>
    )
}
